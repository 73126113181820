/**
 * ヘッダーメニューの開閉class
 */

export default class HeaderMenu {
  menuBtn: HTMLElement | null;

  constructor(menuBtn: HTMLElement | null) {
    this.menuBtn = menuBtn;
  }

  toggleMenu() {
    const headerNavi = document.getElementById('fn-headerNavi');
    if (this.menuBtn && headerNavi) {
      this.menuBtn.addEventListener('click', () => {
        headerNavi.classList.toggle('is-open');
      });
    }
  }
}
