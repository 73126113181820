import '../css/style.css';
import Swiper from 'swiper';
import HeaderMenu from './modules/menu';

window.addEventListener('DOMContentLoaded', () => {
  const mebuBtn = document.getElementById('fn-headerMenu');
  const headerMenu = new HeaderMenu(mebuBtn);
  headerMenu.toggleMenu();

  const swiper = new Swiper('#fn-slider', {
    /*
    autoplay: {
      delay: 5000
    },
    */
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  });
});
